
export const REACT_APP_API_BASE_URL = "https://api.h1communicator.com/api/"
// export const REACT_APP_API_BASE_URL = "https://uat-api.h1-communicator.com/api/"


// REACT_APP_FIREBASE_API_KEY  
export const REACT_APP_FIREBASE_API_KEY = "AIzaSyCBDuHM5jjGvrKrnKXdlr8B11ab6hJ_7OY"
export const REACT_APP_FIREBASE_AUTH_DOMAIN = "cascade-59d73.firebaseapp.com"
export const REACT_APP_FIREBASE_DATABASE_URL = "https://cascade-59d73-default-rtdb.firebaseio.com"
export const REACT_APP_FIREBASE_PROJECT_ID = "cascade-59d73"
export const REACT_APP_FIREBASE_STORAGE_BUCKET = "cascade-59d73.appspot.com"
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = "409772646528"
export const REACT_APP_FIREBASE_APP_ID = "1:409772646528:web:909589ba2486433f1f0665"
export const REACT_APP_FIREBASE_MEASUREMENT_ID = "G-1LWREE1MEP"
export const REACT_APP_FIREBASE_VAPID_KEY = "BFamXYV82u30r6S4_zM6cG9lWWhe6LpB3dg5LKwgHjjPvuzZmB7j1Bna5ij69-BRPDkHguZBh2n6snXT70iEGbU"
export const REACT_APP_FIREBASE_VPI_PRIVET = "vGqi2Xg72JkQAM4cdgpmMCVnkcA_qOJEk2ORJpfBA58"


export const showOldLog = false